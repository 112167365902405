.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text-desk {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 7.5% 0 7.5% 0;


    & h2 {
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 3.5vw;
      letter-spacing: 0.02em;

      color: #FFFFFF;
    }

    & p {
      width: 90%;
      margin: 1.2vw 0 0 7vw;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 300;
      font-size: 1.5vw;
      line-height: 2.7vw;

      letter-spacing: 0.02em;

      color: #FFFFFF;
    }
  }

  &__text-mob {
    display: flex;
    flex-direction: column;
    width: 84%;
    margin: 15% 0 15%  0;


    & h2 {
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 500;
      font-size: 5.6vw;
      letter-spacing: 0.05em;

      color: #FFFFFF;

    }

    & p {
      width: 90%;
      margin: 4vw 0 0 7vw;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 200;
      font-size: 4.26vw;

      letter-spacing: 0.04em;

      color: #FFFFFF;
    }
  }
}