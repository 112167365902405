.wrapper {
  margin: 0 auto;

  width: 80%;
}

.photo-section {
  margin-top: 7.5%;
}

.line {
  position: absolute;

  border: 0.05vw solid white;
}

.first-tw-room-mob {

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 177.3vw;
  }

  &__top {
    display: flex;
    justify-content: center;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    width: 84vw;
    height: 60vw;

    &_img {

      &:nth-last-child(1) {
        align-self: flex-end;
      }
    }
  }

  &__line {
    margin: 2.26vw 0 0 2.26vw;
    width: 79.5vw;
    height: 55.48vw;
  }
}

.second-tw-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__top {
    width: 84vw;
    margin: 19.3vw 0;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 4.1vw;
    text-align: center;

    color: #FFFFFF;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 173.3vw;
  }
}

.third-tw-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 190.6vw;
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    width: 73.3vw;
    height: 118.6vw;

    & img {
      width: 60vw;
      height: 57.3vw;

      &:nth-last-child(1) {
        align-self: flex-end;
      }
    }
  }

  &__line {
    margin-top: 3vw;
    height: 112vw;
    width: 67.3vw;
  }
}

.fourth-tw-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 174.6vw;
  }

  &__line {
    margin: 3.65vw 0 0 3.35vw;
    width: 53.3vw;
    height: 50vw;
  }
}

.fifth-tw-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 269.3vw;
  }

  &__bottom {
    display: flex;
    justify-content: center;
    height: 134.6vw
  }

  &__line {
    margin-top: 4.3vw;
    width: 38vw;
    height: 126vw;
  }
}

.sixth-tw-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__paragraph {
    width: 84vw;
    margin: 19.3vw 0;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 4.1vw;
    text-align: center;

    color: #FFFFFF;
  }
}

.seventh-tw-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 235.9vw;
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    width: 73.3vw;
    height: 118.6vw;

    & img {
      width: 60vw;
      height: 57.3vw;

      &:nth-last-child(1) {
        align-self: flex-start;
      }
    }
  }

  &__line {
    margin-top: 3vw;
    height: 112vw;
    width: 67.3vw;
  }
}

.eighth-tw-room-mob {

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__paragraph {
    width: 84vw;
    margin: 19.3vw 0;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 4.1vw;
    text-align: center;

    color: #FFFFFF;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 235.2vw;
  }
}