@import "./styles/variabels";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $cream-white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #737367;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}