.burger {
  display:flex;
  flex-direction:column;
  margin-right: 30px;
  width: 40px;
  cursor:pointer;
  z-index: 1000;

  & span{
    background: #ffffff;
    height: 1px;
    margin: 5px 0;
    transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

    &:nth-of-type(1){
      width:50%;
    }

    &:nth-of-type(2){
      width:100%;
    }

    &:nth-of-type(3){
      width:75%;
    }
  }

  &__input {

    &[type="checkbox"] {
      display:none;

      &:checked ~ span:nth-of-type(1) {
        transform-origin:bottom;
        transform:rotatez(45deg) translate(5px,0px)
      }

      &:checked ~ span:nth-of-type(2) {
        transform-origin:top;
        transform:rotatez(-45deg)
      }

      &:checked ~ span:nth-of-type(3) {
        transform-origin:bottom;
        width:50%;
        transform: translate(17px,-5px) rotatez(45deg);
      }
    }
  }
}
