.opacity-animation-0_5s {
  animation: header-opacity 0.5s;
}

.opacity-animation-1_5s {
  animation: header-opacity 1.5s;
}

.opacity-animation-2s {
  animation: header-opacity 2s;
}

.opacity-animation-2_5s {
  animation: header-opacity 2.5s;
}

.opacity-animation-3s {
  animation: header-opacity 3s;
}

.opacity-animation-3_5s {
  animation: header-opacity 3s;
}

.photo-section {
  margin-top: 7.5%;
}

.first-tw-room {
  margin-top: 4%;

  &__wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }

  &__left {
    display: flex;
    align-items: center;
    height: 100%;

    & img {
      width: 54.75vw;
      height: 40.1vw;
    }
  }
  
  &__line {
    position: absolute;
    border: 0.05vw solid white;
    height: 42.5vw;
    width: 21.1%;
    right: 11.3vw;
  }
  
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 29.5%;
    height: 45vw;

    &_img {
      width: 16.6vw;
      height: 21.875vw;

      &:nth-last-child(1) {
        align-self: flex-end;
      }
    }
  }
}

.second-tw-room {

  &__wrapper {
    margin-left: 7.2vw;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 47vw;
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 42.45vw;
    height: 91%;

    & img {
      width: 100%;
      height: 27.8vw;
    }

    & p {
      width: 55%;
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 1.1vw;
      line-height: 1.7vw;
      color: white;
    }
  }

  &__right {
    width: 36vw;
  }
}

.third-tw-room {

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin-left: 10vw;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 38.5vw;
  }

  &__line {
    position: absolute;
    border: 0.05vw solid white;
    height: 36vw;
    width: 19.6vw;
    margin: 0 0 0.1vw 1.16vw;
  }

  &__right {
    width: 61.6vw;
    height: 43.1vw;
  }
}

.fourth-tw-room {

  &__wrapper {
    margin: 0 auto;

    display: flex;
    justify-content: space-between;

    width: 80%;
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;

    & img {
      width: 23.4vw;
      height: 22.4vw;
    }

    & p {
      width: 62%;
      margin-top: 5vw;
      text-align: left;

      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 1.1vw;
      line-height: 1.7vw;
      color: white;
    }
  }

  &__line {
    position: absolute;
    border: 0.05vw solid white;

    width: 20.4vw;
    height: 19.4vw;

    margin: 1.5vw 0 0 5.55vw;
  }

  &__right {
    width: 48.1vw;
    height: 70vw;
  }
}

.fifth-tw-room {

  &__wrapper {
    margin: 0 auto;

    width: 80%;
    display: flex;
  }

  &__left {
    width: 42.3vw;
    height: 61.1vw;
  }

  &__right {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    & img {
      width: 18.85vw;
      height: 52.6vw;
    }
  }

  &__line {
    position: absolute;
    border: 0.05vw solid white;

    width: 15.85vw;
    height: 49.6vw;
  }
}

.sixth-tw-room {

  &__wrapper {
    margin: 0 auto;
    width: 80%;
  }

  &__top-img {
    width: 72.9vw;
    height: 41.1vw;
  }

  &__bottom-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 7.7vw;

    & p {
      width: 29%;
      margin-left: 2vw;
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 1.1vw;
      line-height: 1.75vw;
      color: white;
    }

    & img {
      width: 48vw;
      height: 72.9vw;
    }
  }
}

.seventh-tw-room {

  &__wrapper {
    margin: 0 auto;
    width: 80%;

    display: flex;
    justify-content: space-between;
  }

  &__left {
    width: 53.9vw;
    height: 71.6vw;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    & img {
      width: 22.65vw;
      height: 30.2vw;

      &:nth-last-child(1) {
        margin-top: 2.4vw;
      }
    }
  }

  &__line {
    position: absolute;

    width: 19.65vw;
    height: 59.8vw;

    margin-top: 1.5vw;

    border: 0.05vw solid white;
    z-index: 1000;
  }
}

.eighth-tw-room {

  &__wrapper {
    margin: 0 auto;

    width: 80%;

    display: flex;
    justify-content: space-between;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & p {
      align-self: center;

      width: 64%;
      margin-bottom: 13vw;

      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 1.1vw;
      line-height: 1.75vw;
      color: white;
    }
  }
}

.tw-footer {
  margin: 15.625vw 0 4vw 0;

  &__wrapper {
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;

    border: 0.05vw solid white;
    background-color: rgba(0, 0, 0, 1);

    transition: 0.25s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }

    & img {
      width: 100%;
      height: 4.17vw;
      opacity: 0.6;
      z-index: 10;
      border: 0.05vw solid white;
    }

    & p {
      position: absolute;
      margin-bottom: 0.25vw;

      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 1.875vw;

      letter-spacing: 0.02em;

      color: #FFFFFF;
      z-index: 1000;

      &:nth-last-child(2) {
        font-size: 1.25vw;
        margin-left: 59vw;
      }
    }
  }

  &__arrow {
    position: relative;

    left: 39vw;
    bottom: 9.15vw;

    z-index: 1000;
    transform: scale(0.09);
  }
}