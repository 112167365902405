.wrapper {
  margin: 0 auto;

  width: 80%;
}

.photo-section {
  margin-top: 7.5%;
}

.line {
  position: absolute;

  border: 0.05vw solid white;
  z-index: 1000;
}

.first-ch-room {
  margin-top: 4%;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0 auto;

    width: 87.5%;
  }

  &__left {
    width: 27%;
    height: 45vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & img {
      width: 16.6vw;
      height: 21.875vw;

      &:nth-last-child(1) {
        align-self: flex-end;
      }
    }
  }

  &__line {
    width: 20.9vw;
    height: 42.1vw;

    margin: 1.4vw 0 0 1.2vw;
    z-index: 1000;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;

    & p {
      align-self: center;

      width: 25%;

      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 1.1vw;
      line-height: 1.75vw;
      color: white;
    }
  }
}

.second-ch-room {

  &__top {
    margin-left: 6.25vw;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    & p {
      margin-bottom: 12vw;
      align-self: center;

      width: 64%;

      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 1.1vw;
      line-height: 1.75vw;
      color: white;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 54%;
  }
}

.third-ch-room {

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 37%;
    height: 46.8vw;

    & img {
      width: 22.3vw;
      height: 22.3vw;

      &:first-child {
        align-self: flex-end;
      }
    }
  }

  &__line {
    margin: 1vw 0 0 1vw;
    width: 27.5vw;
    height: 44.8vw;
  }

  &__right {
    width: 48.1vw;
    height: 70vw;
  }
}

.fourth-ch-room {
  display: flex;
  flex-direction: column;

  &__top {
    margin: 0 auto;
    width: 80%;
    height: 42vw;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin-left: 7.5%;
  }

  &__line {
    width: 17.4vw;
    height: 57.9vw;
    margin: 1vw 0 0 1vw;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60vw;
    width: 23%;

    & img {
      width: 14.7vw;
      height: 19.375vw;

      &:nth-last-child(2) {
        align-self: flex-end;
      }
    }
  }

  &__right {
    width: 62vw;
    height: 52vw;
  }
}

.fifth-ch-room {

  &__left {
    width: 42.3vw;
    height: 65.1vw;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__line {
    width: 26.4vw;
    height: 50vw;
    margin: 1.5vw 0 0 1.5vw;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 37%;
    height: 53vw;

    & img {
      width: 22.4vw;
      height: 26vw;

      &:nth-last-child(1) {
        align-self: flex-end;
      }
    }
  }
}

.sixth-ch-room {

  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 87%;
    margin: 0 auto;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 55vw;
    align-items: center;

    & img {
      width: 36.45vw;
      height: 26.5vw;
    }

    & p {
      width: 63%;

      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 1.1vw;
      line-height: 1.75vw;
      color: white;
    }
  }

}

.seventh-ch-room {

  &__left {
    width: 49vw;
    height: 65.1vw;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__line {
    width: 20.15vw;
    height: 59.9vw;
    margin-top: 1.25vw;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 62.4vw;
  }
}