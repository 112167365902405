@import "./variabels";

.navbar-bg {
  position: fixed;

  display: flex;
  justify-content: center;

  z-index: 1001;
  transition: 0.25s;

  width: 100%;

  background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.2) 70%, rgba(0,0,0,0) 100%);
}

.navbar {
  display: flex;
  position: fixed;
  list-style: none;
  margin-top: 30px;

  & li {
    height: 100%;
    padding: 0 30px;
    transition: 0.2s;

    &:hover {
      transform: skewX(-10deg);
      transform-origin: bottom left;
    }

    & a {
      text-decoration: none;
      cursor: pointer;
      font-size: calc(22px + 0.05vw);
      font-family: "Playfair Display", serif;
      font-style: normal;
      color: #ffffff;
    }
  }
}

.on-page {
  transform: skewX(-10deg);
  transform-origin: bottom left;
}

.under {
  margin-top: 80px;

  & li {

    & a {
      font-style: italic;
    }

    &:hover {
      transform: scale(1.05);
      transform-origin: center;
    }
  }

}

.is-shown {
  top: 0;
}

.is-hidden {
  top: -150px;
}

.default-bg {
  height: 10%;
}

.upscale-bg {
  height: 15%;
}

.open {
  display: flex;

  animation: opacity-loading 0.5s, up-to-down 0.25s;
}

.close {
  display: none;

  animation: opacity-disolving 0.5s, down-to-up 0.25s;
}

@keyframes opacity-disolving {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes up-to-down {
  0% {
    margin-top: 50px;
  }

  100% {
    margin-top: 80px;
  }
}

@keyframes down-to-up {
  0% {
    margin-top: 80px;

  }

  100% {
    margin-top: 50px;
  }
}

