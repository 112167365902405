@import "variabels";

.chelsea {
  position: relative;
  margin-top: 1vw;
  height: 47vw;

  &__header {
    position: absolute;
    top: 6vw;
    right: 4%;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 44%;
    height: 0;

    z-index: 100;

    background: rgba(209, 209, 209, 0.4);
    box-shadow: 4px 7px 12px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(4px);

    transition: 1s;
    animation: opacity-loading 1s;

    & p {
      margin: 0 0 15px 15px;
      text-transform: uppercase;
      color: #ffffff;
      font-size: 7.3vw;
      line-height: 72px;
      font-family: "Playfair Display", serif;
      letter-spacing: 0.9vw;

      animation: header-opacity 3.2s;
    }
  }

  &__container {
    position: relative;
    top: 10vw;
    left: 10%;
    height: 30vw;
    width: 80%;

    display: flex;
    justify-content: flex-start;
  }

  &__wrapper {
    position: relative;
    width: 35%;
    height: 100%;

    background-color: $navi-blue;

    z-index: 99;
    box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.25);

    animation: opacity-loading 1s;
  }

  &__ticket {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 90%;

    animation: header-opacity 2s;

    &_desc {
      width: 73%;
      text-align: left;

      animation: header-opacity 2s;

      & p {
        font-family: 'Roboto', sans-serif;
        font-weight: 200;
        font-size: 1.3vw;
        line-height: 2vw;
        color: #FFFFFF;
      }
    }

    &_btn {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 80%;
      height: 4vw;

      border: 0.05vw solid #FFFFFF;
      background-color: rgba(255, 255, 255, 0);
      border-radius: 0;
      text-decoration: none;
      cursor: pointer;

      transition: 0.5s;

      & p {
        color: #FFFFFF;
        font-family: "Playfair Display", serif;
        font-size: 1.5vw;
        text-transform: uppercase;
      }

      & img {
        position: absolute;
        transform: rotate(180deg) scale(0.8);
        margin: 0.3vw 18vw 0 0;
      }

      &:hover {
        background-color: rgba(24, 24, 24, 0.2);
        box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &__imgs {
    display: flex;
    position: relative;
    width: 62%;
    right: 0.7vw;
    z-index: 99;

    & img {
      filter: drop-shadow(-4px 4px 12px rgba(0, 0, 0, 0.25));
    }

    &_one {
      height: 29.9vw;
      width: 19.53vw;
      animation: header-opacity 1.7s;
    }

    &_wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-right: 0.7vw;

      width: 55%;
      height: 100%;
    }

    &_two {
      height: 14.6vw;
      width: 19.53vw;
      align-self: flex-end;
      animation: header-opacity 2.5s;
    }

    &_three {
      height: 14.6vw;
      width: 19.53vw;
      animation: header-opacity 3.3s;
    }
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: relative;

    right: 5vw;
    width: 6.4vw;
    height: 12.5vw;

    z-index: 99 ;

    background-color: $navi-blue;
    filter: drop-shadow(-4px 4px 12px rgba(0, 0, 0, 0.25));

    cursor: pointer;
    animation: arrow-left-move 1.2s;

    &:hover {
      background-color: #323d4d;
      transition: 0.5s;
    }

    &_right {
      width: 45%;
      height: 22%;
      margin-left: 1.5vw;

      border: solid #FAFAF2;
      border-width: 0 0.25vw 0.25vw 0;
      display: inline-block;
      padding: 3px;

      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }

  &__blocker {
    height: 13%;
  }
}

.chelsea-mob {
  position: relative;
  height: 140vw;

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 55%;
    height: 0;

    left: 41vw;
    top: 6vw;

    background: rgba(209, 209, 209, 0.2);
    backdrop-filter: blur(3px);
    box-shadow: 4px 7px 12px rgba(0, 0, 0, 0.05);

    z-index: 100;
    transition: 1s;
    animation: header-opacity 3.2s;

    & p {
      margin: 0 0 1vw 0;
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 9.5vw;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #FFFFFF;
    }
  }

  &__img {
    position: absolute;
    left: 5vw;
    z-index: 98;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);

    animation: header-opacity 1.5s, img-right-move 1.5s;
  }

  &__ticket {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 70%;
    height: 60vw;

    top: 62vw;
    left: 27vw;

    padding: 7vw;
    background-color: $navi-blue;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);

    z-index: 99;

    animation: header-opacity 1.5s, short-left-move 1.5s;

    &_desc {
      width: 97%;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 200;
      font-size: 3.8vw;
      line-height: 5vw;

      color: #ffffff;
      animation: header-opacity 2s;
    }

    &_btn {
      display: flex;
      justify-content: center;

      width: 100%;
      padding: 2vw 0;
      border: 0.2vw solid white;

      margin-top: 5vw;
      text-decoration: none;
      text-transform: none;
      animation: header-opacity 2s;

      & p {
        margin: 0 0 0.5vw 0;
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 4.5vw;
        text-transform: uppercase;

        color: #FAFAF2;
      }

      & img {
        position: absolute;
        transform: rotate(180deg);
        right: 50vw;
        top: 46.2vw;
	width: 16.5vw;
      }
    }
  }

  &__bg-txt {
    position: absolute;
    top: -2vw;
    right: -10vw;
    padding: 0;
    margin-left: 7%;
    z-index: 10;

    color: $home-page-back;
    text-shadow: -0.5px 0.5px 0 #FFFFFF,
    0.5px 0.5px 0 #FFFFFF,
    0.5px -0.5px 0 #FFFFFF,
    -0.5px -0.5px 0 #FFFFFF;

    font-family: "Playfair Display", serif;
    font-size: 22vw;
    font-weight: 700;
    letter-spacing: 42px;
    line-height: 22vw;
    text-transform: uppercase;
    overflow: hidden;
    animation: opacity-loading 1s;

    &:nth-last-child(1) {
      top: 94vw;
      right: 62vw;
      letter-spacing: 1vw;

    }
  }
}

@keyframes arrow-left-move {
  0% {right: 20vw;}
  20% {right: 20vw;}
  100% {right: 5vw;}
}
