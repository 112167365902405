.wrapper {
  margin: 0 auto;

  width: 80%;
}

.photo-section {
  margin-top: 7.5%;
}

.line {
  position: absolute;

  border: 0.05vw solid white;
}

.first-ch-room-mob {

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 177.3vw;
  }

  &__top {
    display: flex;
    justify-content: center;
    height: 112vw;
  }

  &__mid {
    display: flex;
    justify-content: space-between;
    width: 84vw;
    height: 60vw;

    &_img {

      &:nth-child(1) {
        align-self: flex-end;
      }
    }
  }

  &__line {
    margin: 2.26vw 0 0 2.26vw;
    width: 79.5vw;
    height: 55.48vw;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 148.5vw;
    margin-top: 15.3vw;

    & p {
      width: 84vw;
      margin: 19.3vw 0;

      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 4.1vw;
      text-align: center;

      color: #FFFFFF;
    }
  }
}

.second-ch-room-mob {

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 173.3vw;
  }

  &__bottom {
    width: 84vw;
    margin: 19.3vw 0;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 4.1vw;
    text-align: center;

    color: #FFFFFF;
  }
}

.third-ch-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 177.3vw;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    width: 84vw;
    height: 60vw;

    &_img {

      &:nth-child(3) {
        align-self: flex-end;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    height: 112vw;
  }

  &__line {
    margin: 2.26vw 0 0 2.26vw;
    width: 79.5vw;
    height: 55.48vw;
  }
}

.fourth-ch-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 300vw;
  }

  &__mid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 49.3vw;
    height: 155vw;

    & img {
      width: 38vw;
      height: 50vw;

      &:nth-child(3) {
        align-self: flex-end;
      }
    }
  }

  &__line {
    margin: 3vw 0 0 3vw;
    width: 43.3vw;
    height: 149vw;
  }
}

.fifth-ch-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 265.6vw;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 73.3vw;
    height: 138.5vw;

    & img {
      width: 56.6vw;
      height: 66.6vw;

      &:nth-child(3) {
        align-self: flex-end;
      }
    }
  }

  &__line {
    margin: 4.3vw 0 0 4.3vw;
    width: 64.7vw;
    height: 129.9vw;
  }
}

.sixth-ch-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__paragraph {
    width: 84vw;
    margin: 19.3vw 0;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 4.1vw;
    text-align: center;

    color: #FFFFFF;
  }
}

.seventh-ch-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 235.9vw;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 73.3vw;
    height: 118.6vw;

    & img {
      width: 60vw;
      height: 57.3vw;

      &:nth-last-child(1) {
        align-self: flex-end;
      }
    }
  }

  &__line {
    margin: 3vw 0 0 3vw;
    height: 112.6vw;
    width: 67.4vw;
  }
}

.ch-footer-mob {
  margin-top: 14.6vw;

  &__wrapper {
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 92vw;
    height: 24vw;

    border: 0.05vw solid white;
    background-color: rgba(0, 0, 0, 1);

    & img {
      position: absolute;
      width: 92vw;
      height: 24vw;
      opacity: 0.6;
      z-index: 10;
      border: 0.05vw solid white;
    }

    & p {
      position: absolute;
      margin-bottom: 8vw;

      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 6.93vw;
      letter-spacing: 0.02em;

      color: #FFFFFF;
      z-index: 1000;

      &:nth-last-child(2) {
        font-size: 4.26vw;
        margin-top: 14vw;
      }
    }
  }

  &__arrow {
    position: relative;
    bottom: 4vw;
    transform: scale(0.15) rotate(90deg);
    z-index: 10000;
  }
}