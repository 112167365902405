@import "variabels";

.about {
  display: flex;
  align-items: center;

  position: relative;
  margin-top: 5vw;

  height: 32vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    bottom: 5vw;
    left: 12vw;

    height: 77%;
  }

  &__name {
    display: flex;
    align-items: center;
    flex-direction: column;

    height: 90%;
    animation: header-opacity 3s, move-right 3s;

    & p:nth-child(1) {
      height: 12vw;

      font-family: 'Playfair Display', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 9.4vw;
      letter-spacing: 0.02em;
      color: $cream-white;
    }

    &_hr {
      width: 10vw;
    }

    & p:nth-last-child(1) {
      height: 7vw;

      font-family: 'Playfair Display', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 5.2vw;
      line-height: 6vw;
      letter-spacing: 0.02em;
      color: $cream-white;
    }

    &_btn {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 70%;
      height: 3vw;

      border: 0.05vw solid #FFFFFF;
      background-color: rgba(255, 255, 255, 0);
      border-radius: 0;
      text-decoration: none;
      transition: 0.5s;

      animation: header-opacity 3s;

      & p {
        color: #FFFFFF;
        font-family: "Playfair Display", serif;
        font-size: 1.5vw;
        text-transform: capitalize;
      }

      & img {
        position: absolute;
        transform: scale(0.65);
        margin: 0.3vw 0 0 20vw;
      }

      &:hover {
        background-color: rgba(24, 24, 24, 0.2);
        box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &__portrait {
    position: absolute;
    right: 12vw;
    height: 125%;
    width: 35vw;
    border: calc(7px) solid $cream-white;
    z-index: 1000;

    animation: header-opacity 3s;
  }

  &__arrow {
    position: absolute;
    right: 5vw;
    top: 14.3vw;

    width: 4vw;
    height: 4vw;

    border: solid #FAFAF2;
    border-width: 0 0.25vw 0.25vw 0;
    display: inline-block;
    padding: 3px;

    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);

    animation: header-opacity 4s;

    &_bg {
      position: absolute;
      right: 0;
      height: 100%;
      width: 14vw;
      transition: 0.5s;

      &:hover {
        background-color: rgba(24, 24, 24, 0.3);;
      }
    }
  }
}

.about-mob {
  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 1000;

  &__portrait {
    position: relative;
    bottom: 10vw;
    width: 84vw;
    height: 64vw;

    border: 5px solid white;

    animation: header-opacity 2.5s;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    position: relative;

    bottom: 5vw;
  }

  &__name {
    position: relative;
    
    display: flex;
    align-items: center;
    flex-direction: column;

    animation: header-opacity 3s, top-drop-bottom 3.5s;

    & p:nth-child(1) {
      height: 22vw;
      font-family: 'Playfair Display', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 17vw;
      letter-spacing: 0.02em;
      color: $cream-white;
    }

    &_hr {
      width: 20vw;
    }

    & p:nth-last-child(1) {
      font-family: 'Playfair Display', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 9.4vw;
      line-height: 11vw;
      letter-spacing: 0.02em;
      color: $cream-white;
    }

    &_btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      top: 7vw;

      border: 1px solid white;
      text-decoration: none;

      height: 10vw;
      width: 60vw;

      color: #FFFFFF;

      animation: header-opacity 3.2s;

      & p {
        font-size: 4vw;
      }

      & img {
        position: absolute;
        left: 49vw;
        top: 3.55vw;
	width: 18.5vw;
      }
    }
  }
}

.about-whole-wrapper {
  height: 32vw;
  width: 0;
  background-color: $navi-blue;
}

.about-whole-wrapper-mob {
  position: absolute;
  align-self: flex-start;
  height: 150vw;
  width: 100vw;
  background-color: $navi-blue;
}

@keyframes move-right {
  0% {left: 8vw}
  100% {left: 12vw}
}

@keyframes top-drop-bottom {
  0% {bottom: 15vw}
  100% {bottom: 0}
}

@keyframes top-drop-top {
  0% {top: 0}
  100% {top: 7vw}
}
