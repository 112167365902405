@import "variabels";

.twickenham {
  position: relative;
  margin-top: 1vw;
  height: 44vw;

  &__header {
    position: absolute;
    top: 6vw;
    left: 4%;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 63%;
    height: 0;

    z-index: 100;

    background: rgba(209, 209, 209, 0.4);
    box-shadow: 4px 7px 12px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(4px);

    & p {
      margin: 0 0 0.78vw 0.78vw;
      text-transform: uppercase;
      color: #ffffff;
      font-size: 7.3vw;
      line-height: 3.75vw;
      font-family: "Playfair Display", serif;
      letter-spacing: 0.9vw;

    }
  }

  &__container {
    position: relative;
    top: 10vw;
    left: 10%;
    height: 30vw;
    width: 80%;

    display: flex;
    justify-content: flex-start;
  }

  &__wrapper {
    position: relative;
    width: 35%;
    height: 100%;

    background-color: $olive;

    z-index: 99;
    box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.25);
  }

  &__ticket {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 90%;

    &_desc {
      width: 73%;

      & p {
        font-family: 'Roboto', sans-serif;
        font-weight: 200;
        font-size: 1.3vw;
        line-height: 2vw;
        color: #FFFFFF;
      }
    }

    &_btn {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 80%;
      height: 4vw;

      border: 0.05vw solid #FFFFFF;
      background-color: rgba(255, 255, 255, 0);
      border-radius: 0;
      text-decoration: none;
      cursor: pointer;

      transition: 0.5s;

      & p {
        color: #FFFFFF;
        font-family: "Playfair Display", serif;
        font-size: 1.5vw;
        text-transform: uppercase;
      }

      & img {
        position: absolute;
        transform: scale(0.8);
        margin: 0.3vw 0 0 18vw;
      }

      &:hover {
        background-color: rgba(24, 24, 24, 0.2);
        box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &__imgs {
    display: flex;
    position: relative;
    width: 62%;
    left: 0.7vw;
    z-index: 99;

    & img {
      filter: drop-shadow(-4px 4px 12px rgba(0, 0, 0, 0.25));
    }

    &_one {
      height: 29.9vw;
      width: 19.53vw;
    }

    &_wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-left: 0.7vw;

      width: 55%;
      height: 100%;
    }

    &_two {
      height: 14.6vw;
      width: 19.53vw;
    }

    &_three {
      height: 14.6vw;
      width: 19.53vw;
      align-self: flex-end;
    }
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: relative;

    left: 5vw;
    width: 6.4vw;
    height: 12.5vw;

    z-index: 99 ;

    background-color: $olive;
    filter: drop-shadow(-4px 4px 12px rgba(0, 0, 0, 0.25));

    cursor: pointer;

    &:hover {
      background-color: #979889;
      transition: 0.5s;
    }

    &_right {
      width: 45%;
      height: 22%;
      margin-right: 1.5vw;

      border: solid #FAFAF2;
      border-width: 0 0.25vw 0.25vw 0;
      display: inline-block;
      padding: 3px;

      transform: rotate(-45deg);
    }
  }

  &__bg-txt {
    position: absolute;
    top: -2vw;
    left: 0;
    padding: 0;
    margin-left: 7%;
    z-index: 10;

    color: $home-page-back;
    text-shadow: -0.5px 0.5px 0 #FFFFFF,
    0.5px 0.5px 0 #FFFFFF,
    0.5px -0.5px 0 #FFFFFF,
    -0.5px -0.5px 0 #FFFFFF;

    font-family: "Playfair Display", serif;
    font-size: 22vw;
    font-weight: 700;
    letter-spacing: 42px;
    line-height: 22vw;
    text-transform: uppercase;
    overflow: hidden;
    animation: opacity-loading 1s;

    &:nth-last-child(1) {
      margin: 25vw 0 0 43vw;
      letter-spacing: 0.1em;
    }
  }

  &__blocker {
    height: 13%;
  }
}

.twickenham-mob {
  position: relative;
  top: 5vw;
  height: 145vw;

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 80%;
    height: 0;

    left: 3.5vw;
    top: 6vw;

    background: rgba(209, 209, 209, 0.2);
    box-shadow: -4px 7px 12px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(3px);

    z-index: 100;
    transition: 1s;
    animation: header-opacity 3.2s;

    & p {
      margin: 0 0 1vw 1vw;
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 9.5vw;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #FFFFFF;
    }
  }

  &__img {
    position: absolute;
    left: 13vw;
    z-index: 98;
    box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.25);

    animation: header-opacity 1.5s, img-left-move 1.5s;
  }

  &__ticket {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 70%;
    height: 60vw;

    top: 57vw;
    left: 5vw;

    padding: 7vw;

    background-color: $olive;
    box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.25);
    animation: header-opacity 1.5s, short-right-move 1.5s;

    z-index: 99;

    &_desc {
      width: 97%;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 200;
      font-size: 3.8vw;
      line-height: 5vw;

      color: #ffffff;
      animation: header-opacity 2s;
    }

    &_btn {
      display: flex;
      justify-content: center;

      width: 100%;
      padding: 2vw 0;
      border: 0.2vw solid white;

      margin-top: 5vw;
      text-decoration: none;
      text-transform: none;
      animation: header-opacity 2s;

      & p {
        margin: 0 0 0.5vw 0;
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 4.5vw;
        text-transform: uppercase;

        color: #FAFAF2;
      }

      & img {
        position: absolute;
        left: 50vw;
        top: 46.2vw;
	width: 16.5vw;
      }
    }
  }
}

.mob {
  text-align: center;
  margin-left: 2%;

  &:nth-last-child(1) {
    top: 65vw;
  }
}

.opacity-loading-1s {
  animation: opacity-loading 1s;
  transition: 1s;
}

.load-header-text-opacity {
  animation: header-opacity 3.2s;
}

.header-opacity-1_7s {
  animation: header-opacity 1.7s;
}

.header-opacity-2s {
  animation: header-opacity 2s;
}

.header-opacity-2_5s {
  animation: header-opacity 2.5s;
}

.header-opacity-3_3s {
  animation: header-opacity 3.3s;
}

.arrow-from-right-to-left {
  animation: arrow-right-move 1.2s;
}

@keyframes opacity-loading {
  0% {opacity: 0}
  100% {opacity: 1}
}

@keyframes header-opacity {
  0% {opacity: 0}
  50% {opacity: 0}
  100% {opacity: 1}
}

@keyframes short-right-move {
  0% {left: -5vw;}
  20% {left: -5vw;}
  100% {left: 5vw;}
}

@keyframes short-left-move {
  0% {left: 34vw;}
  20% {left: 34vw;}
  100% {left: 27vw;}
}

@keyframes img-left-move {
  0% {left: 20vw;}
  20% {left: 20vw;}
  100% {left: 13vw;}
}

@keyframes img-right-move {
  0% {left: -10vw;}
  20% {left: -10vw;}
  100% {left: 5vw;}
}
