@import "./variabels";

html {
  background-color: #959789;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;

  width: 100%;
  height: 100vh;

  &__navbar {
    position: fixed;
    z-index: 1000000;
    width: 100%;
    height: 80px;
    top: -88px;

    background: rgba(78, 78, 78, 0.2);
    backdrop-filter: blur(6px);
    transition: 0.5s;

    &_is-shown {
      top: 0;
    }

    &_is-hidden {
      top: -88px;
    }

    &_wrapper {
      margin: 0 auto;

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      height: 100%;
    }

    &_menu {
      display: none;
      position: fixed;
      opacity: 0;

      left: 0;
      top: 0;

      height: 120vh;
      width: 100%;
      z-index: 10;
      background-color: $olive;
      animation: ani 0.2s forwards;

      & nav {
        margin: 0 auto;

        margin-top: 80px;

        list-style: none;
        width: 88%;

        & li {
          display: block;
          position: relative;
          height: 100%;

          font-size: calc(27px + 0.05vw);
          font-family: "Playfair Display", serif;
          font-style: normal;

          & a {
            text-decoration: none;
            text-transform: none;
            color: $cream-white;
            cursor: pointer;
          }
        }
      }
    }

    &_project-list {
      margin-left: 2vw;

      & a {
        font-size: calc(24px + 0.05vw);
        font-family: "Playfair Display", serif;
        font-style: italic;
        text-decoration: none;
        text-transform: none;
        color: $cream-white;
        cursor: pointer;
      }
    }

    &_active {
      display: block;

      @keyframes ani {
        0% {opacity: 0;}
        100% {opacity: 1;}
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 88px;
    width: 27%;
    max-width: 25vw;
    background-color: $olive;
  }

  &__contacts {
    position: relative;
    top: 1px;
    padding: 20px 0;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;

    color: #FAFAF2;
  }

  &__main-text {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 74vw;
    height: 14.5vw;

    background-color: $bg-blr-white;
    backdrop-filter: blur(4px);
    z-index: -1;

    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 97%;
      height: 87%;

      border: 1px solid $cream-white;
      color: #FFFFFF;

      & p {
        margin-bottom: 1.05vw;
        font-family: "Playfair Display", serif;
        font-size: 9vw;
      }
    }
  }

  &__util-txt {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    font-size: 12vw;

    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #FFFFFF;

  }

  &__arrow {
    position: absolute;
    width: 125px;
    height: 125px;
    top: 84vh;

    cursor: pointer;
  }

  &__arrow-mob {
    position: absolute;
    width: 100px;
    height: 50px;
    top: 88vh;

    animation: main-txt-paragraph 2s;
    cursor: pointer;
  }
}

.load-main-txt {
  animation: main-txt 2s;
}

.load-main-txt-wrapper {
  animation: main-txt-wrapper 2.1s;
}

.load-main-txt-p {
  animation: main-txt-paragraph 3s;
}

.load-flat-arrow {
  animation: main-txt-paragraph 2s;

  &:hover {
    animation: main-txt-paragraph 2s, arrow-toggle 1s forwards;
  }
}

.loaded-flat-arrow {
  &:hover {
    animation: arrow-toggle 1s forwards;
  }
}

.loaded-main-txt {
  width: 74vw;
  height: 14.5vw;
  opacity: 1;
}

.loaded-main-txt-wrapper {
  width: 97%;
  height: 87%;
  opacity: 1;
}

.loaded-main-txt-p {
  opacity: 1;
}

@keyframes main-txt {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }

  30% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  50% {
    height: 14.5vw;
    width: 0;
  }

  100% {
    width: 74vw;
    height: 14.5vw;
  }
}

@keyframes main-txt-wrapper {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }

  30% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  60% {
    height: 87%;
    width: 0;
  }

  100% {
    width: 97%;
    height: 87%;
  }
}

@keyframes main-txt-paragraph {
  0% {opacity: 0}
  65% {opacity: 0}
  100% {opacity: 1}
}

@keyframes arrow-toggle {
  0% {margin-top: 0}
  25% {margin-top: 0.5vw}
  50% {margin-top: 0}
  100% {margin-top: 0.5vw}
}


.home-bg {
  background-image: url("../../public/home-bg.jpg");
}

.home-bg-mob {
  background-image: url("../../public/HomeBackgroundMob.jpg");
}

.notting-bg {
  background-image: url("../../public/NottinHillBG.jpg");
}

.twick-bg {
  background-image: url("../../public/TwickenhamBG.jpg");
}

.notting-bg-mob {
  background-image: url("../../public/NottinHillBG-mob.jpg");
}

.twick-bg-mob {
  background-image: url("../../public/TwickenhamBG-mob.jpg");
}

.chelsea-bg {
  background-image: url("../../public/ChelseaBG.jpg");
}

.chelsea-bg-mob {
  background-image: url("../../public/ChelseaBG-mob.jpg");
}

.press-bg {
  background-image: url("../../public/Press-bg.png");
}

.press-bg-mob {
  background-image: url("../../public/Press-bg.png");
}

.about-bg {
  background-image: url("../../public/About-bg.jpg");
}

.about-bg-mob {
  background-image: url("../../public/About-bg.jpg");
}

.background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1000;
}

.background__blanket {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0.25) 25%, rgba(0,0,0,0.25) 100%);
}

.utils-background__blanket {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.25);
}

.space {
  height: 80px;
}

.hide-project-list {
  display: none;
}
