.wrapper {
  margin: 0 auto;

  width: 80%;
}

.photo-section {
  margin-top: 7.5%;
}

.line {
  position: absolute;

  border: 0.05vw solid white;
}

.first-nt-room-mob {

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 177.3vw;
  }

  &__top {
    display: flex;
    justify-content: center;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    width: 84vw;
    height: 60vw;

    &_img {

      &:nth-last-child(1) {
        align-self: flex-end;
      }
    }
  }

  &__line {
    margin: 2.26vw 0 0 2.26vw;
    width: 79.5vw;
    height: 55.48vw;
  }
}

.second-nt-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 202vw;
  }

  &__top {
    width: 84vw;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 4.1vw;
    text-align: center;

    color: #FFFFFF;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 118.6vw;
  }

  &__line {
    margin-top: 3vw;

    width: 54vw;
    height: 112.6vw;
  }
}

.third-nt-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 200vw;
  }

  &__top {
    width: 84vw;
    margin: 0 0 19.3vw 0;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 4.1vw;
    text-align: center;

    color: #FFFFFF;
  }

  &__quote {
    margin: 7vw 0 0 60vw;

    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: 3.5vw;
    line-height: 2vw;
    color: #FAFAF2;
  }

  &__bottom {
    margin-top: 6vw;
  }
}

.fourth-nt-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 130vw;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    width: 84vw;
    height: 60vw;

    &_img {

      &:nth-child(3) {
        align-self: flex-end;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    height: 64.6vw;
  }

  &__line {
    margin: 2.26vw 0 0 2.26vw;
    width: 79.5vw;
    height: 55.48vw;
  }
}

.fifth-nt-room-mob {
  margin-top: 7.1vw;

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 169vw;
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__text {
    width: 84vw;
    margin: 9.65vw 0;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 4.1vw;
    text-align: center;

    color: #FFFFFF;
  }

  &__bottom {
    width: 84vw;
    margin: 19.3vw 0;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 4.1vw;
    text-align: center;

    color: #FFFFFF;
  }

  &__quote {
    margin: 7vw 0 0 60vw;

    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: 3.5vw;
    line-height: 2vw;
    color: #FAFAF2;
  }
}

.sixth-nt-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 177.2vw;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 73.3vw;
    height: 105.2vw;

    & img {
      width: 60vw;
      height: 57.3vw;

      &:nth-last-child(1) {
        align-self: flex-end;
      }
    }
  }

  &__line {
    margin: 4vw 0 0 4vw;
    height: 97.5vw;
    width: 65.4vw;
  }
}

.seventh-nt-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 273.9vw;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    width: 84vw;
    height: 60.6vw;

    & img {
      width: 39.33vw;
      height: 51.3vw;

      &:nth-last-child(1) {
        align-self: flex-start;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    height: 134.6vw
  }

  &__line {
    margin-top: 4.8vw;
    width: 38vw;
    height: 124vw;
  }
}

.eighth-nt-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 auto;
    width: 84vw;
  }

  &__line {
    margin: 20vw 0 0 50vw;
    width: 32vw;
    height: 32vw;
  }

  &__last {
    margin-top: 10vw;
  }
}

.ninth-nt-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    height: 197.2vw;
  }

  &__top {
    width: 92vw;
    height: 70.6vw;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 4.1vw;
    text-align: center;

    color: #FFFFFF;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 118.6vw;
  }

  &__line {
    margin-top: 3vw;

    width: 54vw;
    height: 112.6vw;
  }
}

.tenth-nt-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__paragraph {
    width: 84vw;
    margin: 19.3vw 0;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 4.1vw;
    text-align: center;

    color: #FFFFFF;
  }

  &__quote {
    margin: 7vw 0 0 60vw;

    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: 3.5vw;
    line-height: 2vw;
    color: #FAFAF2;
  }
}

.eleventh-nt-room-mob {
  margin-top: 15.3vw;

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    height: 133.2vw;
  }

  &__top {
    width: 58.6vw;
    height: 58.6vw;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 4.1vw;
    text-align: center;

    color: #FFFFFF;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 70.6vw;
  }

  &__line {
    margin-top: 4vw;

    width: 50.6vw;
    height: 50.6vw;
  }
}