.util-header {
  display: flex;
  justify-content: center;
  height: 44vw;

  &__color-bg {
    position: absolute;
    z-index: -1001;
    width: 100%;
    height: 100vh;
  }

  &__bg {
    margin: 0 auto;
    position: absolute;
    height: 44vw;
    width: 90vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1000;
  }

  &__blanket {
    width: 100%;
    height: 100%;
  }

  & p {
    align-self: center;
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    font-size: 6.25vw;
    line-height: 8.3vw;
    /* identical to box height */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #FFFFFF;
  }
}

.header-resize {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 88vw;

  &__bg {
      margin: 0 auto;
      position: absolute;
      height: 91vw;
      width: 100vw;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: -1000;
    }
}