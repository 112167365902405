.opacity-animation-0_5s {
  animation: header-opacity 0.5s;
}

.opacity-animation-1_5s {
  animation: header-opacity 1.5s;
}

.opacity-animation-2s {
  animation: header-opacity 2s;
}

.opacity-animation-2_5s {
  animation: header-opacity 2.5s;
}

.opacity-animation-3s {
  animation: header-opacity 3s;
}

.opacity-animation-3_5s {
  animation: header-opacity 3s;
}

.photo-section {
  margin-top: 7.5%;
}

.first-nt-room {
  margin-top: 4%;

  &__wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 87.5%;
  }

  &__left {
    display: flex;
    align-items: center;
    height: 100%;

    & img {
      width: 60.4vw;
      height: 47.5vw;
    }
  }
  
  &__line {
    position: absolute;
    border: 0.05vw solid white;
    height: 42.5vw;
    width: 21%;
    right: 7.4vw;

    margin-top: 8.2vw;
  }
  
  &__right {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 45vw;

    top: 4.2vw;

    &_img {
      width: 16.6vw;
      height: 21.875vw;
    }
  }
}

.second-nt-room {

  &__wrapper {
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    width: 75.5%;
    height: 43vw;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    width: 25vw;

    & img:nth-last-child(2) {
      align-self: end;
    }
  }

  &__line {
    position: absolute;
    border: 0.05vw solid white;
    height: 40.75vw;
    width: 22.5%;
    left: 13.55vw;

    margin-top: 1.15vw;
  }

  &__right {
    align-self: center;
  }
}

.third-nt-room {

  &__wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 87.5%;

    margin: 0 auto;
  }

  &__left {
    display: flex;
    flex-direction: column;

    width: 26.6vw;
  }

  &__text {
    margin-top: 3vw;
    align-self: center;

    width: 85%;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1vw;
    line-height: 1.75vw;
    color: white;
  }

  &__quote {
    margin: 1vw 0 0 20vw;

    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: 1vw;
    line-height: 2vw;
    color: #FAFAF2;
  }

  &__right {
    width: 54.4vw;
    height: 40.1vw;

    margin-left: 2vw;
  }
}

.fourth-nt-room {

  &__wrapper {
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 87.5%;
  }

  &__left {
    & img {
      width: 55vw;
      height: 41.6vw;
    }
  }

  &__line {
    position: absolute;
    border: 0.05vw solid white;
    height: 42.5vw;
    width: 25%;
    right: 9.5vw;

    margin-bottom: 3.4vw;
  }

  &__right {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    height: 45vw;
    width: 27.5vw;

    left: 3vw;
    bottom: 1.6vw;

    &_img {
      width: 23vw;
      height: 21.875vw;

      &:nth-last-child(1) {
        align-self: flex-end;
      }
    }
  }
}

.fifth-nt-room {
  margin-top: 4vw;

  &__wrapper {
    margin: 0 auto;

    width: 80%;
    height: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    width: 79.1vw;
    height: 41.6vw;
  }

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    & img {
      width: 43.22vw;
      height: 26vw;
    }
  }

  &__text {
    margin-left: 6.5vw;
    width: 25vw;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1vw;
    line-height: 1.75vw;
    color: white;
  }

  &__quote {
    margin: 1vw 0 0 26.5vw;

    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: 1vw;
    line-height: 2vw;
    color: #FAFAF2;
  }
}

.sixth-nt-room {

  &__wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 87.5%;
  }

  &__right {
    & img {
      width: 67.5vw;
      height: 50.625vw;
    }
  }

  &__line {
    position: absolute;
    border: 0.05vw solid white;
    height: 38vw;
    width: 14.8vw;
    left: 7.5vw;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    height: 41vw;
    width: 17.5vw;

    &_img {
      width: 14.8vw;
      height: 19.375vw;

      &:nth-last-child(1) {
        align-self: flex-end;
      }
    }
  }
}

.seventh-nt-room {

  &__wrapper {
    margin: 0 auto;
    width: 87.5%;
    height: 82.9vw;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__top {
    display: flex;
    justify-content: space-between;

    height: 52vw;
  }

  &__left {
    width: 66vw;
    height: 52vw;
  }

  &__right {
    display: flex;

    position: relative;
    top: 7.8vw;

    & img {
      width: 19.5vw;
      height: 61vw;
    }
  }

  &__line {
    position: absolute;

    width: 16.5vw;
    height: 58vw;

    margin-top: 1.5vw;
    left: 1.5vw;

    border: 0.05vw solid white;
    z-index: 1000;
  }

  &__bottom {
    display: flex;
    align-items: flex-start;
    align-self: center;
    justify-content: space-between;

    height: 29.1vw;
    width: 40.5vw;
    margin-left: 4vw;

    & img {
      width: 19.5vw;
      height: 22.4vw;

      &:nth-last-child(1) {
        align-self: flex-end;
      }
    }
  }
}

.eighth-nt-room {
  margin-top: 4vw;

  &__wrapper {
    margin: 0 auto;

    width: 80%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__line {
    position: absolute;

    width: 25.64vw;
    height: 25.64vw;

    right: 17.5vw;

    border: 0.05vw solid white;
    z-index: 1000;
  }

  &__right {
    margin-left: 3vw;
  }
}

.ninth-nt-room {
  &__wrapper {
    margin: 0 auto;

    display: flex;
    justify-content: flex-start;

    width: 87.5%;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 20.8vw;
    height: 54vw;
  }

  &__right {
    margin: 12vw 0 0 1.5vw;
  }

  &__line {
    position: absolute;

    width: 17.8vw;
    height: 50.5vw;

    margin: 1.5vw 0 0 1.5vw;

    border: 0.05vw solid white;
    z-index: 1000;
  }
}

.tenth-nt-room {
  &__wrapper {
    margin: 0 auto;

    display: flex;
    justify-content: flex-end;

    width: 87.5%;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 20.8vw;
    height: 54vw;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 50.6vw;

    margin: 12vw 0 0 1.5vw;
  }

  &__text {
    width: 25vw;

    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1vw;
    line-height: 1.75vw;
    color: white;
  }

  &__quote {
    margin: 1vw 0 0 20vw;

    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: 1vw;
    line-height: 2vw;
    color: #FAFAF2;
  }

  &__line {
    position: absolute;

    width: 17.8vw;
    height: 50.5vw;

    margin: 1.5vw 0 0 1.5vw;

    border: 0.05vw solid white;
    z-index: 1000;
  }
}

.eleventh-nt-room {

  &__wrapper {
    margin: 0 auto;

    width: 87.5%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__line {
    position: absolute;

    width: 25.64vw;
    height: 25.64vw;

    left: 7.5vw;
    margin-top: 8vw;

    border: 0.05vw solid white;
    z-index: 1000;
  }

  &__left {
    margin-top: 8vw;
  }

  &__right {
    margin-left: 3vw;
  }
}
