.press-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__first {
    margin-top: 7.5%;
    width: 100vw;

    display: flex;
    justify-content: space-between;
  }

  &__second {
    margin: 7.5vw 0 7.5% 10vw;
    transform: rotate(-1deg);
  }

  &__third {
    margin: 0 10vw 0 0;
    transform: rotate(1deg);
  }

  &__stack {
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      position: absolute;
      box-shadow: 4px 12px 4px rgba(0, 0, 0, 0.5);

      &:first-child {
        z-index: 100;
        right: 15vw;
      }

      &:nth-child(2) {
        rotate: 65deg;
        right: 11vw;
      }

      &:nth-child(3) {
        rotate: -100deg;
        right: 11vw;
        z-index: 99;
      }
    }
  }

  &__last {
    margin: 7.5% 0 7.5% 0;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: 100vw;

    &_text {
      display: flex;
      flex-direction: column;
      margin-right: 12vw;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 100;
      font-size: 6.25vw;
      line-height: 7.3vw;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #FFFFFF;

      & p {

        &:nth-child(2) {
          font-weight: 700;
        }

        &:nth-last-child(1) {
          align-self: flex-end;
        }
      }
    }

    & img {
      rotate: -2deg;
    }
  }
}