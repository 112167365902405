@import "variabels";

.footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 45vw;
  background-color: #EFEFEB;
  z-index: 1;

  &__wrapper {
    position: relative;
    display: flex;

    justify-content: space-around;
    height: 25vw;
    width: 80%;
  }

  &__angle {
    display: inline-block;
    padding: 3px;

    width: 3vw;
    height: 2vw;
    border: solid #181818;
    border-width: 0 0.07vw 0.07vw 0;

    &:first-child {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      align-self: flex-end;
    }

    &:nth-last-child(1) {
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
    }
  }

  &__content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-self: center;
    height: 80%;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    width: 28%;

    &_header {
      font-size: 1.4vw;
      font-style: normal;
      font-weight: 700;
      font-family: "Playfair Display", serif;
      letter-spacing: 0.05em;

      color: #585858;
      text-transform: uppercase;
    }

    &_address {
      width: 70%;
      height: 50%;
      margin: 1.5vw 0 1.5vw 2vw;

      text-wrap: avoid;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 100;
      font-size: 1.2vw;
      line-height: 1.7vw;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      user-select: text;
    }

    &_list {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 50%;
      width: 47%;
      margin: 0 0 1.5vw 0;

      text-wrap: avoid;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 100;
      font-size: 1.2vw;
      line-height: 1.7vw;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      & li {
        margin-left: 1vw;
        transition: 0.5s;

        & a {
          text-decoration: none;
          color: #585858;
        }

        &:hover {
          transform: skewX(-10deg);
          transform-origin: bottom left;
        }
      }
    }

    &_bott {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 1.4vw;
      line-height: 0.5vw;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      color: #585858;
      cursor: pointer;
      border: none;
      background-color: transparent;
    }

    &_message {
      position: absolute;
      margin: 14.7vw 0 0 -0.5vw;
      padding: 0.5vw 4vw;

      font-family: 'Roboto';
      font-size: 0.9vw;
      font-weight: 400;

      background-color: $chelsea-bg;
      color: $cream-white;
      border-radius: 7px;
    }

    &:nth-last-child(1) {
      align-items: flex-end;
    }
  }
}

.footer-mob {
  display: flex;
  justify-content: center;
  z-index: -100;
  background-color: #E5E4DB;

  &__img {
    padding: 4vw;
  }

  &__angle {
    display: inline-block;

    width: 5vw;
    height: 3vw;
    border: solid #181818;
    border-width: 0 0.2vw 0.2vw 0;
    padding: 4vw;

    &:first-child {
      position: relative;
      left: 2vw;
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      align-self: flex-end;
    }

    &:nth-last-child(1) {
      position: relative;
      right: 2vw;
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
    }
  }

  &__wrapper {
    display: flex;
    justify-content: center;

    width: 100%;
    padding: 15vw 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 10vw 0;
    width: 82%;
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_header {
      font-size: 7vw;
      font-style: normal;
      font-weight: 700;
      font-family: "Playfair Display", serif;
      letter-spacing: 0.05em;

      text-wrap: none;

      color: #585858;
      text-transform: uppercase;

    }

    &_address {
      width: 90%;
      padding: 4vw 0;
      text-align: center;

      text-wrap: avoid;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 100;
      font-size: 4vw;

      letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    &_list {
      list-style: none;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 2vw;

      & li {
        text-wrap: avoid;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        font-size: 5vw;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-left: 3vw;
      }

      & a {
        text-decoration: none;
        color: #585858;
      }
    }

    &_bott {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 6vw;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      color: #585858;
      border: none;
      background-color: transparent;
    }

    &_message {
      position: absolute;
      margin-top: 26.75vw;
      padding: 1.5vw 15vw;

      font-family: 'Roboto';
      font-weight: 100;
      font-size: 4vw;

      background-color: $chelsea-bg;
      color: $cream-white;
      border-radius: 7px;
    }
  }
}

.gf-footer {
  height: 4.17vw;
  width: 100%;

  &__wrapper {
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;

    border: 0.05vw solid white;
    background-color: rgba(0, 0, 0, 1);

    transition: 0.25s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }

    & img {
      width: 100%;
      height: 4.17vw;
      opacity: 0.6;
      z-index: 10;
      border: 0.05vw solid white;
    }

    & p {
      position: absolute;
      margin-bottom: 0.25vw;

      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 1.875vw;

      letter-spacing: 0.02em;

      color: #FFFFFF;
      z-index: 1000;

      &:nth-last-child(2) {
        font-size: 1.25vw;
        margin-left: 59vw;
      }
    }
  }

  &__arrow {
    position: relative;

    left: 39vw;
    bottom: 9.15vw;

    z-index: 1000;
    transform: scale(0.09);
  }
}

.gb-footer {
  height: 4.17vw;
  width: 100%;

  &__wrapper {
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;

    border: 0.05vw solid white;
    background-color: rgba(0, 0, 0, 1);

    transition: 0.25s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }

    & img {
      width: 100%;
      height: 4.17vw;
      opacity: 0.6;
      z-index: 10;
    }

    & p {
      position: absolute;
      margin-bottom: 0.25vw;

      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 1.875vw;

      letter-spacing: 0.02em;

      color: #FFFFFF;
      z-index: 1000;

      &:nth-last-child(2) {
        font-size: 1.25vw;
        margin-right: 59vw;
      }
    }
  }

  &__arrow {
    position: relative;

    right: 39vw;
    bottom: 9.15vw;

    z-index: 1000;
    transform: scale(0.09) rotate(180deg);
  }
}

.footer-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-spliter {
  width: 13.33vw;
  margin: 2.83vw;
  border-bottom: 1px solid white;
}

.gf-footer-mob {
  margin-top: 14.6vw;

  &__wrapper {
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 92vw;
    height: 24vw;

    border: 0.05vw solid white;
    background-color: rgba(0, 0, 0, 1);

    & img {
      position: absolute;
      width: 92vw;
      height: 24vw;
      opacity: 0.6;
      z-index: 10;
      border: 0.05vw solid white;
    }

    & p {
      position: absolute;
      margin-bottom: 8vw;

      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 6.93vw;
      letter-spacing: 0.02em;

      color: #FFFFFF;
      z-index: 1000;

      &:nth-last-child(2) {
        font-size: 4.26vw;
        margin-top: 14vw;
      }
    }
  }

  &__arrow {
    position: relative;
    bottom: 4vw;
    transform: scale(0.15) rotate(90deg);
    z-index: 10000;
  }
}

.gb-footer-mob {
  margin-top: 24.6vw;
  height: 24vw;

  &__wrapper {
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 92vw;
    height: 24vw;

    border: 0.05vw solid white;
    background-color: rgba(0, 0, 0, 1);

    & img {
      position: absolute;
      width: 92vw;
      height: 24vw;
      opacity: 0.6;
      z-index: 10;
      border: 0.05vw solid white;
    }

    & p {
      position: absolute;
      margin-bottom: 8vw;

      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 4.26vw;
      letter-spacing: 0.02em;

      color: #FFFFFF;
      z-index: 1000;

      &:nth-child(2) {
        font-size: 6.93vw;
        margin-top: 14vw;
      }
    }
  }

  &__arrow {
    position: relative;
    bottom: 35vw;
    transform: scale(0.15) rotate(-90deg);
    z-index: 10000;
  }
}