@import "variabels";

.bg {
  overflow: hidden;
}

.home-page {
  background-color: $home-page-back;
}

.notting-page {
  background-color: $royal-brown;
}

.twickenham-page {
  background-color: $twickenham-bg;
}

.chelsea-page {
  background-color: $chelsea-bg;
}

.press-page {
  background-color: $royal-brown;
}

.about-page {
  background-color: $navi-blue;
}